import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {TemplateTypeService} from '../../../services/template-type.service';
import {Template} from 'src/app/models/template';
import {TemplateService} from 'src/app/services/template.service';
import {debounceTime, filter, switchMap, tap} from 'rxjs/operators';
import {GlobalService} from '../../../services/global.service';
import {PaginationDataModel} from '../../../models/pagination-data.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DEFAULT_HEADER_FOOTER} from '../../../shared/utils/const.util';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-create-template-dialog',
    templateUrl: './create-template-dialog.component.html',
    styleUrls: ['./create-template-dialog.component.scss']
})
export class CreateTemplateDialogComponent implements OnInit {
    types: any;
    selectedType: any;
    templateName: string;
    template: Template;
    footers: any;
    headers: any;
    footerFilteringControl = new FormControl();
    headerFilteringControl = new FormControl();
    searching = false;
    templateForm: FormGroup;
    isEdit = false;

    constructor(public dialogRef: MatDialogRef<CreateTemplateDialogComponent>,
                private templateTypeService: TemplateTypeService,
                private templateService: TemplateService,
                private globalService: GlobalService,
                private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data,
                private translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.checkUpdate();
        this.getTemplateForm();
        this.template = new Template();
        this.getTypes();
        this.getHeadersOrFooters(DEFAULT_HEADER_FOOTER.HEADER_FILTER).subscribe(data => {
            this.setHeaderData(data);
        });
        this.getHeadersOrFooters(DEFAULT_HEADER_FOOTER.FOOTER_FILTER).subscribe(data => {
            this.setFooterData(data);
        });
        this.onChangeFooterSelection();
        this.onChangeHeaderSelection();
    }

    checkUpdate() {
        if (Object.keys(this.data).length !== 0) {
            this.isEdit = true;
        }
    }

    setFooterData(data) {
        this.footers = data.rows;
        this.footers.unshift({ id: 0, name: this.translate.instant('actions.NoFooter') });
        if (this.isEdit) {
            if (Number(this.data.footer_id) === 0) {
                this.templateForm.get('footerTemplate').setValue({ id: 0, name: this.translate.instant('actions.NoFooter') });
            } else {
                this.globalService.getFooter(this.data.footer_id).subscribe(footer => {
                    const item = this.footers.find(res => Number(res.id) === Number(footer.id));
                    if (!item) this.footers.push(item);
                    this.templateForm.get('footerTemplate').setValue(
                        this.footers.find(resp => Number(resp.id) === Number(footer.id))
                    );
                });
            }
        }
    }

    setHeaderData(data) {
        this.headers = data.rows;
        this.headers.unshift({ id: 0, name: this.translate.instant('actions.NoHeader') });
        if (this.isEdit) {
            this.templateForm.get('templateName').setValue(this.data.name);
            if (Number(this.data.header_id) === 0) {
                this.templateForm.get('headerTemplate').setValue({ id: 0, name: this.translate.instant('actions.NoHeader') });
            } else {
                this.globalService.getHeader(this.data.header_id).subscribe(header => {
                        const item = this.headers.find(res => Number(res.id) === Number(header.id));
                        if (!item) this.headers.push(item);
                        this.templateForm.get('headerTemplate').setValue(
                            this.headers.find(resp => Number(resp.id) === Number(header.id))
                        );
                    }
                );
            }
        }
    }

    getTemplateForm() {
        this.templateForm = this.fb.group({
            templateName: ['', Validators.required],
            selectedType: ['', [Validators.required]],
            headerTemplate: ['', [Validators.required]],
            footerTemplate: ['', [Validators.required]],
        });
    }

    onChangeHeaderSelection() {
        this.headerFilteringControl.valueChanges
            .pipe(
                filter(search => {
                    return !!search || search === '';
                }),
                debounceTime(800),
                tap(() => this.searching = true),
                switchMap(value => {
                    return this.getHeadersOrFooters(DEFAULT_HEADER_FOOTER.HEADER_FILTER, value, 999);
                })
            )
            .subscribe(options => {
                    this.headers = options.rows;
                    this.headers.unshift({ id: 0, name: this.translate.instant('actions.NoHeader') });
                    this.searching = false;
                }, error => {
                    this.searching = false;
                }
            );
    }

    onChangeFooterSelection() {
        this.footerFilteringControl.valueChanges
            .pipe(
                filter(search => {
                    return !!search || search === '';
                }),
                debounceTime(800),
                tap(() => this.searching = true),
                switchMap(value => this.getHeadersOrFooters(DEFAULT_HEADER_FOOTER.FOOTER_FILTER, value, 999))
            )
            .subscribe(options => {
                    this.footers = options.rows;
                    this.footers.unshift({ id: 0, name: this.translate.instant('actions.NoFooter') });
                    this.searching = false;
                }, error => {
                    this.searching = false;
                }
            );
    }

    getHeadersOrFooters(filterData: any, search?: any, limit: number = 10) {
        return this.globalService.getHeadersOrFooters(new PaginationDataModel('id', 'desc', limit, search),
            filterData
        );
    }

    onConfirm(): boolean {
        if (this.templateForm.invalid) {
            return false;
        }
        const formValue = this.templateForm.value;
        this.template.name = formValue.templateName;
        this.template.template_type_id = formValue.selectedType.id;
        this.template.header_id = formValue.headerTemplate.id;
        this.template.footer_id = formValue.footerTemplate.id;

        if (!this.isEdit)
            this.saveTemplate();
        else
            this.updateTemplate();

    }

    private saveTemplate() {
        this.template.status = 'draft';
        this.template.html = '';
        this.template.header_html = '';
        this.template.footer_html = '';
        this.templateService.saveTemplate(this.template).subscribe(
            res => {
                this.dialogRef.close(res.id);
            },
            error => {
                this.dialogRef.close(false);
            });
    }

    private updateTemplate() {
        this.template.id = this.data.id;
        this.templateService.updateTemplate(this.template, this.template.id).subscribe(
            res => {
                this.dialogRef.close(this.template.id);
            },
            error => {
                this.dialogRef.close(false);
            });
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }

    getTypes() {
        this.templateTypeService.getAll().subscribe((res) => {
            this.types = res.rows;
            if (this.isEdit) {
                this.templateForm.get('selectedType').setValue(
                    this.types.find(data => Number(data.id) === Number(this.data.template_type_id))
                );
            }
        });
    }
}
